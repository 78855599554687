import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from 'axios'

import { JsonEditor as JSONEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import { Button } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import LocalForage from 'localforage';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const API_URL = 'https://api.lambda.tazkia.org/update-playlist';



const filenames = [
    'recent-bayan.json',
    'aaj-ki-baat.json',
    'live-bayan-notices.json',
    'news-feed.json',
    'news-ticker.json',
    'q-and-a.json',
    'short-audio-clips.json',
    'special-events.json',
    'videos.json',
    'ramadan-majalis.json',
    'bayan-schedule.json']

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(2),
    },
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        color: "black"
    },
}));

export default function ScrollableTabsButtonAuto() {

    async function fetchData(event, newValue) {

        setLoading('true');

        ReactGA.event({
            category: 'Download File',
            action: 'Download file from S3',
            label: `Download: ${filenames[0]}`
        });

        let file = filenames[0];
        const resp = await axios.post(API_URL, {
            'get': 'true',
            'getPlaylist': file
        })

        setMyList(resp.data.data);
        setOriginalList(resp.data.data);
        setUpdatedJSON(resp.data.data);
        setUpdatedAt(new Date().toLocaleString())
        setLastModifiedAt(new Date(resp.data.lastModified).toLocaleString())
        setUnsavedChanges("");
        setTimeout(() => {
            setLoading('false');
        }, 10);
    }
    React.useEffect(() => {
        fetchData();

    }, []);
    const classes = useStyles();

    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState('true');
    const [editorMode, setEditorMode] = React.useState('tree');
    const [myList, setMyList] = React.useState([]);
    const [originalList, setOriginalList] = React.useState([]);
    const [updatedAt, setUpdatedAt] = React.useState("");
    const [lastModifiedAt, setLastModifiedAt] = React.useState("");
    const [updatedJSON, setUpdatedJSON] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [info, setInfo] = React.useState("");
    const [snackMessage, setSnackMessage] = React.useState("");
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [unsavedChanges, setUnsavedChanges] = React.useState("");


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit = async () => {
        await addToDB();
        uploadToS3();
        setOpen(false);
    }


    let file = "";
    const handleChange = async (event, newValue) => {
        setLoading('true');
        setValue(newValue);
        setMyList([]);
        setOriginalList([]);
        file = filenames[newValue];
        try {
            const resp = await axios.post(API_URL, {
                'get': 'true',
                'getPlaylist': file
            })
            setUpdatedAt(new Date().toLocaleString())
            setLastModifiedAt(new Date(resp.data.lastModified).toLocaleString())
            setMyList(resp.data.data);
            setOriginalList(resp.data.data);
            setUpdatedJSON(resp.data.data);
            setUnsavedChanges("");
        } catch (error) {
            ReactGA.event({
                category: 'S3 Failed Download',
                action: 'Failed S3 Download Try',
                label: `Failed to download: View: ${error.message || error} ${editorMode}, File: ${file}`
            });
        }


        setTimeout(() => {
            setLoading('false');
        }, 10);
        ReactGA.event({
            category: 'Download File',
            action: 'Download file from S3',
            label: `Download: ${filenames[value]}`
        });

    };
    const handleJSONChange = (json) => {
        if (JSON.stringify(json) === JSON.stringify(originalList)) {
            setUnsavedChanges("")
        } else {
            setUnsavedChanges("You have unsaved changes. Press 'Upload to S3' button to publish your changes on S3");
        }
        setUpdatedJSON(json);

    }
    const handleRefresh = () => {
        handleChange({}, value)
    }
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackOpen(false);
    }

    const getSnackBar = () => {
        return (
            <div className={classes.root}>

                <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert severity="success">
                        {snackMessage}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
    const uploadToS3 = async () => {
        ReactGA.event({
            category: 'S3 Uploads',
            action: 'Trigger S3 Upload',
            label: `Trigger Upload: ${editorMode}, File: ${filenames[value]}`
        });
        const cred = await LocalForage.getItem('credentials')
        if (cred && cred.username && cred.password) {
            try {
                const resp = await axios.post(API_URL, {
                    'update': 'true',
                    'updatePlaylist': filenames[value],
                    'username': cred.username,
                    'password': cred.password,
                    'jsonContent': updatedJSON
                })
                if (resp.data === "Successfully Updated") {
                    setInfo()
                    setSnackMessage(`Successfully Uploaded ${filenames[value]}`)
                    setSnackOpen(true);
                    handleRefresh();
                    ReactGA.event({
                        category: 'S3 Uploads',
                        action: 'Successful S3 Upload',
                        label: `Uploaded: ${filenames[value]}, Mode:${editorMode}`
                    });

                } else {
                    setInfo(resp.data.data)
                    handleClickOpen();
                    ReactGA.event({
                        category: 'Sign in',
                        action: 'Open Sign in Dialog',
                        label: `Sign In: ${info}, ${editorMode}, File: ${filenames[value]}`
                    });
                }
            } catch (error) {
                ReactGA.event({
                    category: 'S3 Failed Uploads',
                    action: 'Failed S3 Upload Try',
                    label: `Failed Upload: View:${error.message || error} ${editorMode}, File: ${filenames[value]}`
                });
                alert('Failed to upload file: Error: ' + error.message || error);
            }

        } else {

            setInfo("")
            handleClickOpen();
            ReactGA.event({
                category: 'Sign in',
                action: 'Open Sign in Dialog',
                label: `Open Sign In Dialog: ${info}, ${editorMode}, File: ${filenames[value]}`
            });
        }
    }
    const getDialogContent = () => {
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Sign in</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="error">
                            {info}
                        </DialogContentText>
                        <DialogContentText>
                            To upload file to S3 you must enter a valid admin user name and password. It'll be saved for future operations.
                  </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Username"
                            onChange={e => setUsername(e.target.value)}

                            fullWidth
                        />
                        <TextField

                            margin="dense"
                            id="password"
                            label="Password"
                            type="password"
                            onChange={e => setPassword(e.target.value)}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                  </Button>
                        <Button onClick={handleSubmit} color="primary">
                            Submit
                  </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
    const addToDB = async (user, pass) => {
        // const timestamp = Date.now();

        const obj = {
            username,
            password
        }


        await LocalForage.setItem('credentials', obj)

    }
    const switchToTreeView = () => {

        ReactGA.event({
            category: 'View',
            action: 'Switch to Tree View',
            label: `Switch to tree view: ${filenames[value]}`
        });
        const tempList = updatedJSON || myList;
        setMyList([]);
        setLoading('true');
        setEditorMode('tree');
        setTimeout(() => {
            setMyList(tempList);
            setLoading('false');
        }, 10);



    }

    const switchToCodeView = () => {
        ReactGA.event({
            category: 'View',
            action: 'Switch to Code View',
            label: `Switch to code view: ${filenames[value]}`
        });
        const tempList = updatedJSON || myList;
        setMyList([]);
        setLoading('true');
        setEditorMode('code');
        setTimeout(() => {
            setMyList(tempList);
            setLoading('false');
        }, 10);



    }

    const renderContent = () => {

        if (loading === 'true') {
            return <h2 style={{ color: "grey" }}>Loading ... </h2>;
        } else if (loading === 'false') {
            return (<>

                {
                    unsavedChanges.length > 0 &&
                    <>
                        <Alert severity="warning">
                            {unsavedChanges}
                        </Alert>
                        <Box m={2}></Box>
                    </>
                }


                <JSONEditor
                    mode={editorMode}
                    ace={ace}
                    history={true}
                    theme="ace/theme/github"
                    value={myList}
                    onChange={handleJSONChange}

                />
            </>)
        }
    }

    const getTabContent = () => {
        return (
            <>
                <Box m={1} />   <Typography variant="subtitle1">Last Modified on S3 At: {lastModifiedAt}</Typography>
                <Box m={2} />
                <Typography variant="subtitle1">Downloaded file At: {updatedAt}</Typography>
                <Box m={2} />
                <ButtonGroup variant="outlined" color="primary" aria-label="contained primary button group">
                    <Button onClick={switchToTreeView}>Tree View</Button>
                    <Button onClick={switchToCodeView}>Code View</Button>

                </ButtonGroup>
                <Button onClick={handleRefresh}
                    variant="contained"
                    color="primary"
                    className={classes.button}

                >
                    Redownload from S3
              </Button>
                <Button onClick={uploadToS3}
                    variant="contained"
                    color="secondary"
                    className={classes.button}

                >
                    Upload to S3
              </Button>

                {renderContent()}

                {getSnackBar()}
            </>

        );
    }

    return (

        <div className={classes.root}>
            <AppBar position="sticky" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"

                >
                    <Tab label="Recent Bayans" {...a11yProps(0)} />
                    <Tab label="Aaj Ki Baat" {...a11yProps(1)} />
                    <Tab label="Live Notices" {...a11yProps(2)} />
                    <Tab label="News Feed" {...a11yProps(3)} />
                    <Tab label="News Ticker" {...a11yProps(4)} />
                    <Tab label="Q and A" {...a11yProps(5)} />
                    <Tab label="Short Clips" {...a11yProps(6)} />
                    <Tab label="Special Events" {...a11yProps(7)} />
                    <Tab label="Videos" {...a11yProps(8)} />
                    <Tab label="Ramadan Majalis" {...a11yProps(9)} />
                    <Tab label="Bayan Schedule" {...a11yProps(9)} />
                </Tabs>
            </AppBar>


            <TabPanel value={value} index={0}>
                {getTabContent()}
                {getDialogContent()}
            </TabPanel><TabPanel value={value} index={1}>
                {getTabContent()}
                {getDialogContent()}
            </TabPanel><TabPanel value={value} index={2}>
                {getTabContent()}
                {getDialogContent()}
            </TabPanel><TabPanel value={value} index={3}>
                {getTabContent()}
                {getDialogContent()}
            </TabPanel><TabPanel value={value} index={4}>
                {getTabContent()}
                {getDialogContent()}
            </TabPanel><TabPanel value={value} index={5}>
                {getTabContent()}
                {getDialogContent()}
            </TabPanel><TabPanel value={value} index={6}>
                {getTabContent()}
                {getDialogContent()}
            </TabPanel><TabPanel value={value} index={7}>
                {getTabContent()}
                {getDialogContent()}
            </TabPanel><TabPanel value={value} index={8}>
                {getTabContent()}
                {getDialogContent()}
            </TabPanel><TabPanel value={value} index={9}>
                {getTabContent()}
                {getDialogContent()}
            </TabPanel><TabPanel value={value} index={10}>
                {getTabContent()}
                {getDialogContent()}
            </TabPanel>

        </div >
    );
}
