import React from 'react';
import './App.css';
import TabPanel from './TabPanel';
import ReactGA from 'react-ga';

// Initialize google analytics
ReactGA.initialize('UA-113285216-1');
ReactGA.pageview('Playlist Manager App - Homepage');

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <TabPanel>
        
      </TabPanel>
      </header>
    </div>
  );
}

export default App;
